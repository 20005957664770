import React, { useEffect, useState } from "react";
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Container, Row, Col, Button, Table, Badge, Form } from 'react-bootstrap';
import { getNews, deleteNews } from '../../services/news'
import { getActiveCategories, getCategoryWithSubcategories } from "../../services/categories";
import { getStatuses } from "../../services/statuses";
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from "../../components/news/ConfirmationModal";
import Loading from "../../components/layout/Loading";


function Home() {
    const [qtyNews, setQtyNews] = useState(100);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [idTodelte, setIdToDelete] = useState(0)
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 10, //default page size
    });

    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState('');
    const totalPages = Math.ceil(data.length / pagination.pageSize);
    const [showModal, setShowModal] = useState(false);
    const redirect = (id) => {
        localStorage.setItem('news_id', id);
        navigate(`/AddAndEditNews`);
    };

    const [statusList, setStatusList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState(0);

    const redirectAddNews = (id) => {
        localStorage.setItem('news_id', 0);
        navigate(`/AddAndEditNews`);
    };

    const onClicDeleteNews = (id) => {
        setIdToDelete(id);
        setShowModal(true)
    }

    const handleDelete = () => {
        if (idTodelte !== 0) {
            deleteNews(idTodelte).then((data) => {
                getNews(qtyNews).then((news) => {
                    setData(news);
                    setShowModal(false);
                    setIdToDelete(0)
                });
            });
        }
    };

    const columns = [
        {
            header: 'Acciones',
            accessorKey: 'id',
            cell: ({ row }) => (
                <>
                <Row>
                    <Col  >
                        <Button
                        className="m-1"
                        size="sm"
                        variant="primary"
                        onClick={() => redirect(row.original.id)}
                    >
                        ...
                    </Button>
                    </Col>
                    <Col>
                        <Button 
                    className="m-1"
                    size="sm"
                    variant="danger"
                    onClick={() => onClicDeleteNews(row.original.id)}
                >
                    <i class="bi bi-trash"></i>
                </Button>
                    </Col>
                </Row>
                
                  
              </>
            )
        },
     
        {
            header: 'Id',
            accessorKey: 'id'
        },
        {
            header: 'Archivos',
            accessorKey: 'filesCount',
            cell: ({ row }) => (
                <>
                    <i class="bi bi-file-earmark-binary"></i> {row.original.filesCount}
                </>
            )
        },
        {
            header: 'Título',
            accessorKey: 'title'
        },
        {
            header: 'Título social',
            accessorKey: 'social_title'
        },
        {
            header: 'Sumario',
            accessorKey: 'short_description'
        },
        {
            header: 'Estatus',
            accessorKey: 'status_name',
            cell: ({ row }) => (
                <>
                    <Badge bg={
                        row.original.status_id === (1 || 2 || 5) ? "secondary" :
                            (row.original.status_id === 4 ? "success" : "danger")
                    }>{row.original.status_name}</Badge>        
                    {
                        row.original.scheduled_at ?
                        <>
                              <hr></hr>
                    Programado para el:{row.original.scheduled_at}   
                    {
                        row.original.scheduled_at_section ?
                        <>
                        <hr></hr>
                        Seccion: {row.original.scheduled_at_section}
                        </>:
                        <>
                        <hr></hr>
                        Seccion: Sin asignar
                        </>
                    }
                        </>:
                        null
                    }                    
                       
                </>
            )
        },
        {
            header: 'Fecha de creación',
            accessorKey: 'created_at'
        },
        {
            header: 'Última actualización',
            accessorKey: 'updated_at'
        },
        {
            header: 'Fecha de publicación',
            accessorKey: 'published_at'
        },      
        {
            header: 'Creado por',
            accessorKey: 'created_by_user_name'
        },
        {
            header: 'Categoría',
            accessorKey: 'category_name'
        },
        {
            header: 'Subcategoría',
            accessorKey: 'subcategory_name'
        }
    ]
    const onChangeRecordLimit = (e) => {
        setQtyNews(parseInt(e.target.value));
    }
    const table = useReactTable({
        //data,
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            pagination,
            sorting,
            globalFilter: filtering
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering
    });

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getNews(qtyNews),
            getStatuses(),
            getActiveCategories(),
        ])
            .then(([news, statuses, categories]) => {
                console.log("cantidad", news.length)
                setData(news);
                setFilteredData(news);
                setStatusList(statuses);
                setCategoryList(categories);
            })
            .finally(() => setIsLoading(false));
    }, [])
    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getNews(qtyNews),
            getStatuses(),
            getActiveCategories(),
        ])
            .then(([news, statuses, categories]) => {
                console.log("cantidad", news.length)
                setData(news);
                setFilteredData(news);
                setStatusList(statuses);
                setCategoryList(categories);
            })
            .finally(() => setIsLoading(false));
    }, [qtyNews])

    
    useEffect(() => {
        const filtered = data.filter(item => {
            const matchesSearch = !filtering || item.title.toLowerCase().includes(filtering.toLowerCase());
            return (
                matchesSearch &&
                (selectedStatus === 0 || item.status_id === selectedStatus) &&
                (selectedCategory === 0 || item.category_id === selectedCategory) &&
                (selectedSubCategory === 0 || item.subcategory_id === selectedSubCategory)
            );
        });
        setFilteredData(filtered);
    }, [selectedStatus, selectedCategory, selectedSubCategory, filtering, data]);

    useEffect(() => {
        if (selectedCategory !== 0) {
            getCategoryWithSubcategories(selectedCategory)
                .then(response => {
                    if (response && response.subcategories) {
                        setSubCategoryList(response.subcategories);
                    } else {
                        setSubCategoryList([]);
                    }
                })
                .catch(() => {
                    console.error('Error al obtener subcategorías');
                    setSubCategoryList([]);
                });
        } else {
            setSubCategoryList([]);
        }
    }, [selectedCategory]);

    const onChangeCategory = (e) => {
        setSelectedCategory(parseInt(e.target.value));
    };

    const onChangeSubCategory = (e) => {
        setSelectedSubCategory(parseInt(e.target.value));
    };

    const onChangeStatus = (e) => setSelectedStatus(parseInt(e.target.value));

    return (<>        
            <Row className="mt-3">
                <Col xs={12} sm={6} md={4} className="d-flex align-items-center">
                    <input
                        type="text"
                        className="form-control me-3 w-50"
                        placeholder="Buscar"
                        value={filtering}
                        onChange={e => setFiltering(e.target.value)}
                    />
                    <Button onClick={() => redirectAddNews()} className="btn-sm primary me-2">
                        Agregar nueva noticia
                    </Button>
                </Col>
                
                <Col xs={6} sm={3} md={2}>
                    <Form.Select aria-label="Seleccionar estatus" onChange={onChangeStatus}>
                        <option value={0}>Todos los estatus</option>
                        {statusList.map(status => (
                            <option key={status.id} value={status.id}>{status.name}</option>
                        ))}
                    </Form.Select>
                </Col>
                
                <Col xs={6} sm={3} md={2}>
                    <Form.Select aria-label="Seleccionar categoría" onChange={onChangeCategory}>
                        <option value={0}>Todas las categorías</option>
                        {categoryList.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </Form.Select>
                </Col>
                
                <Col xs={6} sm={3} md={2}>
                    <Form.Select aria-label="Seleccionar subcategoría" onChange={onChangeSubCategory} disabled={subCategoryList.length === 0}>
                        <option value={0}>Todas las subcategorías</option>
                        {subCategoryList.map(subCategory => (
                            <option key={subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                        ))}
                    </Form.Select>
                </Col>

                {/* Nuevo Select para cantidad de registros */}
                <Col xs={6} sm={3} md={2}>
                    <Form.Select aria-label="Seleccionar cantidad de registros" onChange={onChangeRecordLimit}>                                                
                        <option value={100} selected>100 registros</option>
                        <option value={200}>200 registros</option>
                        <option value={400}>400 registros</option>
                        <option value={20000}>+ de 200 registros</option>
                    </Form.Select>
                </Col>
            </Row>
            {
                isLoading ?
                    <Loading /> : (
                        <>
                            <Row>
                                <Col xl={12}>
                                    <Table className='mt-3' striped bordered hover responsive>
                                        <thead>
                                            {
                                                table.getHeaderGroups().map(headerGroup => (
                                                    <tr className='table-dark' key={headerGroup.id} >
                                                        {
                                                            headerGroup.headers.map(header => (
                                                                <th className='text-center'
                                                                    key={header.id}
                                                                    onClick={header.column.getToggleSortingHandler()}
                                                                >
                                                                    {header.column.columnDef.header}
                                                                    {
                                                                        header.column.getIsSorted() ? (header.column.getIsSorted() == "asc" ? <i class="bi bi-arrow-up-short text-white"></i> : <i class="bi bi-arrow-down-short text-white"></i>) : null
                                                                    }
                                                                </th>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </thead>
                                        <tbody>
                                            {
                                                table.getRowModel().rows.map((row) => (
                                                    <tr key={row.id}>
                                                        {
                                                            row.getVisibleCells().map((cell) => (
                                                                <td className='text-center' key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination">
                                            <li className={`page-item ${pagination.pageIndex === 0 ? 'disabled' : ''}`}>
                                                <a className="page-link" onClick={() => table.setPageIndex(0)}>&laquo;</a>
                                            </li>
                                            <li className={`page-item ${pagination.pageIndex === 0 ? 'disabled' : ''}`}>
                                                <a className="page-link" onClick={() => table.previousPage()}>Anterior</a>
                                            </li>
                                            <li className={`page-item ${pagination.pageIndex === totalPages - 1 ? 'disabled' : ''}`}>
                                                <a className="page-link" onClick={() => table.nextPage()}>Siguiente</a>
                                            </li>
                                            <li className={`page-item ${pagination.pageIndex === totalPages - 1 ? 'disabled' : ''}`}>
                                                <a className="page-link" onClick={() => table.setPageIndex(totalPages - 1)}>&raquo;</a>
                                            </li>
                                            <li className='page-item' >
                                                <a className="page-link disabled"  >{pagination.pageIndex + 1}/{totalPages}</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </Col>
                            </Row>
                        </>
                    )
            }

            <ConfirmationModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleConfirm={handleDelete}
            />
        
    </>);
}

export default Home;