import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Importación corregida

const isValidToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Tiempo actual en segundos
    return decoded.exp > currentTime; // Devuelve true si el token no ha expirado
  } catch (error) {
    return false; // Si el token es inválido o no se puede decodificar
  }
};

const clearSessionStorage = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user_id");
  sessionStorage.removeItem("user_name");
};

const ProtectedRoute = ({ element }) => {
  const token = sessionStorage.getItem("token");

  if (!token || !isValidToken(token)) {
    clearSessionStorage(); // Borra todas las variables si el token no es válido
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;